.hcontainer {
  color: white;
  justify-content: center;
  align-items: center;
  position: relative;
  /* height: 100vh; */
}
.home_body {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  /* padding-left: 200px;
  padding-top: 100px; */
}
.carousel-indicators li {
  filter: invert(100%);
}
.home_body_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  /* padding-left: 200px;
  padding-top: 100px; */
  background-color: #141e2e !important;
}
.home_body_2 h1,
h3 {
  margin: 0;
  padding: 0;
}
.home_body_2 h1 {
  font-size: 64px;
}
.home_body_2 h3 {
  font-size: 32px;
}

.home_body h1,
h3 {
  margin: 0;
  padding: 0;
}
.home_body h1 {
  font-size: 64px;
}
.home_body h3 {
  font-size: 32px;
  font-family: cursive;
}
.home_details {
  border-left: 3px solid white;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.home_details button {
  width: 5rem;
  background-color: transparent;
  color: white;
  padding: 10px;
  height: 60px;
  width: 150px;
  border-radius: 5px;
  border: 1px solid #374251;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  text-align: center;
  font-size: 20px;
}

.home_background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* min-height: 60vh; */
  /* background-color: #534b6a75; */
  /* background-color: rgba(22, 22, 22, 0.25); */
  /* filter: blur(3px); */
  z-index: -1;
  /* min-height: 100vh; */
  /* background-image: linear-gradient(to bottom, transparent, #6462624f),
    url("/public/images/bklogo.jpg"); */
  /* background-color: #0d1117; */
  background-size: cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  backdrop-filter: blur(5px);
  clip-path: circle();
}

.home_image img {
  width: 600px;
  height: 500px;
  padding: 0px;
  border-radius: 10px;
  border: 1px solid #374251;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.logo_name {
  animation: color-change 5s infinite;
  transition: all ease-out 0.3s;
  /* font-size: 38px; */
}

@keyframes color-change {
  0% {
    color: rgb(247, 247, 255);
  }
  50% {
    color: rgb(227, 104, 59);
  }
  100% {
    color: rgb(3, 105, 24);
  }
}

.discover_item_img {
  width: 300px;
  height: 300px;
  clip-path: circle(150px at 50% 50%);
  /* border: 1px solid #374251; */
}

.home_discover {
  background-color: #141e2e !important;
  /* background-color: black; */
  padding: 20px;
}
.home_dis_title {
  text-align: center;
}

.home_discover_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px 60px;
}

.discover_item {
  border: 1px solid #374251;
  background-color: black;
  padding: 10px;
  border-radius: 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  word-wrap: break-word;
  align-self: stretch;
}

.discover_details {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  /* line-height: 30px; */
}

.discover_details h1 {
  padding-bottom: 20px;
}
.home_gallery_container {
  background-color: #111927 !important;
  /* background-color: black; */
  padding: 40px;
}

.gallery_image {
  border-radius: 10px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.gallery_image:hover {
  transform: scale(1.01);
}

.home_gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding-top: 40px;
}

.gallery_item {
  background-color: black;
  border-radius: 10px;
  box-shadow: rgba(59, 89, 143, 0.138) 0px 19px 38px,
    rgba(67, 86, 144, 0.32) 0px 8px 7px;
}

@media screen and (max-width: 480px) {
  .home_body {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    flex-direction: column;
    /* padding-left: 200px;
    padding-top: 100px; */
    /* gap: 50px; */
  }
  .home_image {
    /* height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain; */
    display: none;
  }
  .home_image img {
    object-fit: fill;
    width: 100%;
    height: 100%;
    padding: 0px;
    border-radius: 10px;
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  .home_body h1 {
    font-size: 32px;
  }
  .home_body h3 {
    font-size: 18px;
    font-family: cursive;
  }
  .home_details button {
    background-color: transparent;
    color: white;
    padding: 10px;
    width: 120px;
    border-radius: 5px;
    border: 1px solid #374251;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
    text-align: center;
    font-size: 18px;
  }
  .home_details {
    gap: 20px;
    padding: 20px;
    border: 0;
  }
  /* .carousel-item {
    width: 90%;
    height: 90%;
    object-fit: contain;
  } */
  .carousel-control-next,
  .carousel-control-prev {
    display: none !important;
  }

  .carousel-fill {
    height: -o-calc(100vh - 165px) !important;
    height: -webkit-calc(100vh - 165px) !important;
    height: -moz-calc(100vh - 165px) !important;
    height: calc(100vh - 165px) !important;
    width: auto !important;
    overflow: hidden;
    display: inline-block;
    text-align: center;
  }

  .carousel-item {
    text-align: center !important;
  }

  .home_discover_container {
    flex-direction: row;
    /* justify-content: center; */
    gap: 20px;
    overflow-x: scroll;
    padding: 20px 0px;
  }

  .discover_item {
    border-radius: 10px;
    width: 100%;
    flex-direction: column;
    justify-content: normal;
    align-items: center;
  }  

  .discover_item_img {
    width: 250px;
    height: 100%;
    object-fit: fill;
    padding-bottom: 20px;
    clip-path: circle(30% at 50% 50%);
  }
  .discover_details h1 {
    font-size: 28px;
  }
  .discover_details { 
    padding: 10px;
  }
  .home_dis_title {
    font-size: 24px;
  }
  .home_gallery {
    grid-template-columns: repeat(2, 1fr);
  }
  .logo_name {
    font-size: 38px;
  }
  .home_gallery_container {
    padding: 20px;
  }
}

@media screen and (max-width: 768px) and (min-width: 480px) {
  .home_body {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    flex-direction: column;
    /* padding-left: 200px;
    padding-top: 100px; */
    /* gap: 50px; */
  }
  .home_image {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
  }
  .home_image img {
    object-fit: fill;
    width: 400px;
    height: 300px;
    padding: 0px;
    border-radius: 10px;
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  .home_body h1 {
    font-size: 32px;
  }
  .home_body h3 {
    font-size: 18px;
    font-family: cursive;
  }
  .home_details button {
    background-color: transparent;
    color: white;
    padding: 10px;
    width: 120px;
    border-radius: 5px;
    border: 1px solid #374251;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
    text-align: center;
    font-size: 18px;
  }
  .home_details {
    gap: 20px;
    padding: 20px;
    border: 0;
  }
  /* .carousel-item {
    width: 90%;
    height: 90%;
    object-fit: contain;
  } */
  .carousel-control-next,
  .carousel-control-prev {
    display: none !important;
  }

  .carousel-fill {
    height: -o-calc(100vh - 165px) !important;
    height: -webkit-calc(100vh - 165px) !important;
    height: -moz-calc(100vh - 165px) !important;
    height: calc(100vh - 165px) !important;
    width: auto !important;
    overflow: hidden;
    display: inline-block;
    text-align: center;
  }

  .carousel-item {
    text-align: center !important;
  }

  .home_discover_container {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  .discover_item {
    border-radius: 10px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .discover_item_img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    padding-bottom: 20px;
    clip-path: circle(30% at 50% 50%);
  }
  .discover_details h1 {
    font-size: 28px;
  }
  .home_dis_title {
    font-size: 24px;
  }
  .home_gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 900px) {
}
