.carousel-indicators li {
  border-top: unset;
  width: 10px;
  border-bottom: unset;
  border: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 10px;
  background-color: rgba(169, 29, 42, 0.4);
  height: 10px;
  width: 20px;
  padding-bottom: 10px;
}

.carousel-indicators button {
  border-top: unset;
  border-bottom: unset;
  border: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 10px;
  background-color: white;
  height: 3px;
  width: 50px;
  padding-left: 10px;
  margin-left: 5px;
  margin-bottom: 10px;
}

.carousel-indicators .active {
  border-top: unset;
  border-bottom: unset;
  border: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 10px;
  background-color: #a91d2a;
  height: 3px;
  width: 50px;
  margin-left: 5px;
}

.carousel-indicators {
  transition: all ease-out .5s linear;
}