.head {
  position: sticky;
  /* height: 100px;
  width: 100px; */
  padding: 0;
  top: 0;
  left: 0;
  z-index: 999;
}

.head_background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: #534b6a75; */
  /* background-color: #0d1117; */
  /* background-color: #1f2a37; */
  background-color: rgba(22, 22, 22, 0.25);
  filter: blur(3px);
  z-index: -1;
  padding: 10px;
}
.header_container {
  height: 12vh;
  /* background-color: #534b6a75; */
  /* background-color: rgba(22, 22, 22, 0.25); */
  background-color: #1f2a37;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 200px;
  /* border: 1px solid rgba(22, 22, 22, 0.25); */
  border: 1px solid #374251;
  border-left: 0;
  border-right: 0;
  /* border-radius: 10px; */
  /* border-radius: 8px; */
  /* box-shadow: 0 2px 10px rgba(164, 164, 164, 0.35); */
  box-shadow: rgba(50, 50, 93, 0.641) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.93) 0px 8px 16px -8px;
  /* overflow: hidden; */
}
.head_image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_container img {
  width: 150px;
  height: 10vh;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.nav_item {
  padding: 10px;
}
.nav_item a {
  text-decoration: none !important;
  color: white;
}

.nav_item a:hover {
  text-decoration: none !important;
  color: blue;
}
.nav_drop {
  display: none;
}
.dropdown-toggle::after {
  display: none;
}
.btn-success {
  background-color: #374251;
  border-color: white;
}

@media screen and (max-width: 480px) {
  .header_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    background-color: #1f2a37;
    height: 13vh;
    box-shadow: rgba(50, 50, 93, 0.641) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.93) 0px 8px 16px -8px;
    border: 1px solid #374251;
    border-left: 0;
    border-right: 0;
    gap: 0;
    position: relative;
  }
  .nav {
    display: none !important;
  }
  .nav_drop {
    display: block;
  }
  .dropdown-menu {
    margin: 0;
  }
  .header_container img {
    width: 150px;
    height: 12vh;
  }
  .home_body {
    padding: 20px;
  }
}

@media screen and (max-width: 768px) and (min-width: 480) {
  .header_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    background-color: #1f2a37;
    height: 13vh;
    box-shadow: rgba(50, 50, 93, 0.641) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.93) 0px 8px 16px -8px;
    border: 1px solid #374251;
    border-left: 0;
    border-right: 0;
    gap: 0;
    position: relative;
  }
  .nav {
    display: none !important;
  }
  .nav_drop {
    display: block;
  }
  .dropdown-menu {
    margin: 0;
  }
  .header_container img {
    width: 150px;
    height: 15vh;
  }
}

@media screen and (max-width: 900px) {
}
